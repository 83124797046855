<template>
<v-dialog  :fullscreen="$vuetify.breakpoint.width < 600"
            v-model="showDialog" max-width="650"
  >
  <v-card>
    <v-toolbar elevation="1" color="primary" class="black--text" dense>
      <v-icon class="mr-1" color="black">mdi-thought-bubble-outline</v-icon> <b>Éditer mon status</b>
      <v-spacer/>
      <v-btn @click="showDialog = false" 
               icon small color="black" class="mr-0">
              <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-col cols="12">
      <small>
        Votre status vous permet de communiquer rapidement avec les autres pirates.
        Il est visible sur votre profil, ainsi que dans la liste des status sur la carte.
        Lorsque vous changez de status, votre ancien status est effacé, ainsi que tous les commentaires associés.
      </small><br>

      <v-btn color="red" class="mt-2 d-sm-none px-2"  text 
             v-if="$store.state.auth.user.currentMsg != '' && $store.state.auth.user.currentMsg != null" 
             elevation="0" @click="deleteMsg" rounded :loading="loading">
        <v-icon small color="" class="mr-1">mdi-close</v-icon> Effacer mon status
      </v-btn>
      <!-- <v-divider class="my-3"></v-divider> -->
      <v-textarea outlined :rules="[rules.max]" clearable color="primary" class="mt-5"
                  label="Un message à faire passer aux autres pirates ?" 
                  v-model="msg" counter>
      </v-textarea>
      <v-toolbar color="transparent" elevation="0" class="text-right px-0 pb-0">

      <v-btn color="secondary" class="d-none d-sm-flex mr-1" 
             v-if="$store.state.auth.user.currentMsg != '' && $store.state.auth.user.currentMsg != null" 
             elevation="0" @click="deleteMsg" rounded :loading="loading">
        <v-icon small class="mr-1">mdi-close</v-icon> Effacer mon status
      </v-btn>
      <v-spacer></v-spacer>
        <v-btn color="secondary" class="mr-2" elevation="0" @click="showDialog = false" rounded>
          Annuler
        </v-btn>
        <v-btn color="green" elevation="0" @click="saveMsg" rounded :loading="loading">
          <v-icon small class="mr-1">mdi-comment-check</v-icon> Enregistrer
        </v-btn>
      </v-toolbar>
    </v-col>

  </v-card>
</v-dialog>
</template>

<style></style>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

export default {
  name: 'empty',
  components: {  },
  data: () => ({
    showDialog: false,
    msg: "",
    loading: false,
    rules: {
          max: v => v.length <= 1000 || 'Max 1000 caractères',
        },
  }),
  async mounted(){
    
    this.$root.$off('openDialogEditStatus')
              .$on('openDialogEditStatus', async () => { 
      //console.log("on openDialogEditStatus")
      this.msg = this.$store.state.auth.user.currentMsg
      this.showDialog = true
      this.loading = false
    })
    this.$root.$off('closeDialogMsgPerso')
              .$on('closeDialogMsgPerso', async () => { 
      //console.log("on closeDialogMsgPerso")
      this.showDialogRes = false
    })
  },
  methods: {
    deleteMsg(){
      this.msg = ""
      this.saveMsg()
    },
    saveMsg(){
      this.loading = true
      this.$store.dispatch("auth/saveUserCurrentMsg", this.msg)
                 .then(()=>{
                  //console.log("auth/saveUserCurrentMsg ?", res)
                    this.showDialog = false
                    this.loading = false
                    this.$store.dispatch('auth/newStatus') 
                    this.$store.dispatch('app/openSnackbar', 
                                        { show: true, color:'green', 
                                          title: "Votre message perso a été mis à jour",
                                          text: '', // this.$store.state.auth.user.currentMsg,
                                          icon: 'thumb-up' })
                  
                 })
    }

  },
  computed: {
    
    
  }
}
</script>
