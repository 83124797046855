<template>
  <v-dialog scrollable v-model="showPopupComments" 
            max-width="800" :fullscreen="$vuetify.breakpoint.width < 600">
    <v-card color="black">

      <v-toolbar color="transparent" elevation="0">
        <v-icon color="blue" class="mr-2">mdi-comment</v-icon> 
        Commentaires
        <v-spacer></v-spacer>
        <v-btn icon @click="fetchComments()"><v-icon small>mdi-refresh</v-icon></v-btn>
        <v-btn icon @click="closeDialog()"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-divider/>

      <v-card-text class="px-0 px-sm-5" id="commentscroll">

        <v-card-title class="pl-3 pl-sm-0" v-if="crewMsg != null"># {{ crewMsg.subject }}</v-card-title>
        
        <v-divider/><br>

        <template v-for="(comment, k) in comments">

          <!-- pour marquer le début des nouveaux commentaires -->
          <template v-if="nbComPending > 0 && k == (comments.length - nbComPending)">
            <v-chip small color="red" class=""  :key="k">
              <v-icon small class="mt-1">mdi-chevron-down</v-icon>
              {{ nbComPending }} nouveaux commentaires
            </v-chip>
            <v-divider :key="k+'x'" class="mx-3 mb-2 divider-newcom" style=""></v-divider>
          </template>

          <v-card :key="comment._id" outlined class="mb-0 pb-1 pt-3 card-comment" 
                  :class="k >= (comments.length - nbComPending) ? 'newcom' : ''" 
                  color="black">

            <v-row class="mr-0">
              <v-toolbar color="transparent" elevation="0">
              
                <v-card-subtitle class="py-1 px-2" @click="openUserPage(comment.author._id)">
                  <router-link class="white--text userlink" :to="'/user/' + comment.author._id">
                    <v-gravatar height="22" style="border-radius:50%; margin-bottom:-5px;" class="mr-1"
                                :default-img="'retro'" :email="comment.author.name"/>
                    <b style="font-size:15px;">{{ comment.author.name }}</b>
                  </router-link> • 
                  <small>
                  <time-ago :datetime="comment.created" :refresh="60" long 
                              :locale="$vuetify.lang.current">
                  </time-ago>
                </small>
                </v-card-subtitle> 

                <v-spacer></v-spacer>

                <!-- {{$store.state.auth.user.isAdmin}} || {{$store.state.auth.user._id == comment.author._id}} -->

                <v-btn icon small class="mr-1" title="supprimer" 
                       color="grey" @click="startDelete(comment)"
                       v-if="$store.state.auth.user.isAdmin || $store.state.auth.user._id == comment.author._id">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
                
                <v-menu left transition="scale-transition" max-width="210">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small icon v-bind="attrs" v-on="on" color="grey" class="mr-0">
                      <v-icon small>mdi-sticker-emoji</v-icon>
                    </v-btn>
                  </template>
                  <v-card class="pa-2" outlined style="background:rgb(10, 10, 10)">
                    <template v-for="(emo, i) in $store.state.app.emoReactions">
                        <v-btn :key="i" fab small class="ma-1" outlined 
                              :color="emo.color" :title="emo.name"
                              @click="toogleReaction(comment._id, emo.name)">
                          <v-icon >mdi-{{ emo.icon }}</v-icon>
                        </v-btn>
                    </template>
                  </v-card>
                </v-menu>
              
              </v-toolbar>
            </v-row>

            <v-alert dense class="mx-5 mb-1 pl-2"  
                     style="font-size:13px; background:rgb(10, 10, 10)"
                     v-if="comment.refComment != null">
              <v-icon  class="mdi-rotate-270 mr-2" small>mdi-arrow-down-right</v-icon> 
              <v-gravatar height="22" style="border-radius:50%; margin-bottom:-5px;" class="mr-1"
                              :default-img="'retro'" :email="comment.refComment.author.name"
                              :title="'commentaire de ' + comment.refComment.author.name"/>
              {{ comment.refComment.text }}
            </v-alert> 

            <p class="px-5 mb-0 ml-1" style="font-size:15px;" v-html="nl2br(comment.text)"></p> 

            <v-col class="py-1 pb-1 pl-5 text- toolbar-emo">
              <v-btn text small class="mr-1 px-1" title="répondre" 
                      color="grey darken-2" @click="startReply(comment)">
                répondre
              </v-btn>
              <v-btn text small class="mr-1 px-1" title="modifier" 
                      color="grey darken-2" @click="startEdit(comment)"
                      v-if="$store.state.auth.user.isAdmin || $store.state.auth.user._id == comment.author._id">
                modifier
              </v-btn>

              <template v-for="emo in comment.reactions">
                <v-btn x-small rounded class="mr-2 px-1" :class="getReactionColor(emo.name)+'--text'" color="black" v-if="emo.users.length > 0"
                      :title="emo.name" :key="emo.name" @click="toogleReaction(comment._id, emo.name)">
                {{ emo.users.length }} <v-icon small class="ml-1" :color="getReactionColor(emo.name)">mdi-{{ getReactionIcon(emo.name) }}</v-icon>
                </v-btn> 
              </template>
            </v-col>
          </v-card>
          <!-- <v-divider></v-divider> -->
        </template>

        <template v-if="comments.length == 0"><br>
          <span class="px-5"><v-icon small>mdi-cancel</v-icon> Aucun commentaire</span>
        </template>

      </v-card-text>

      <v-col class="pb-0 px-5 mt-3">
        <v-alert dense v-if="replyComment != null">
          <small>
            Réponse à <b class="pr-1">{{ replyComment.author.name }}</b>
            <br>
            {{ replyComment.text.substr(0, 100) }}...
          </small>
        </v-alert>
        <v-textarea outlined color="blue" hide-details v-model="newComment.text"
                    label="Votre commentaire..." :rows="$vuetify.breakpoint.width < 600 ? 3 : 4"
                    :rules="[rules.max]">
        </v-textarea>
      </v-col>

      <v-card-actions class="mb-5 px-5 mt-2">
        <small :class="newComment.text.length > 3000 ? 'red--text' : ''">{{ newComment.text.length }} / 3000</small>
        <v-spacer></v-spacer>

        <v-btn text small class="px-1 py-0" 
               @click="replyComment = null" 
               v-if="replyComment != null">
               Annuler répondre
        </v-btn>
        <v-btn class="px-4" color="blue" rounded @click="sendComment()" >
          <v-icon small>mdi-send</v-icon> Envoyer
        </v-btn>
      </v-card-actions>
    </v-card>


    <v-dialog v-model="showPopupEditComment" 
            max-width="750" :fullscreen="$vuetify.breakpoint.width < 600">
    <v-card color="black" v-if="editComment != null">
      <v-toolbar color="transparent" elevation="0">
        <v-icon color="blue" class="mr-2">mdi-pencil</v-icon> 
        Modifier le commentaire
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialogEditComments()"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-divider/>

      <v-card-text class="pb-0" id="">
        <v-textarea color="blue" outlined class="mt-5" hide-details v-model="editComment.textEdit"></v-textarea>        
      </v-card-text>

      <v-toolbar color="transparent" elevation="0" height="80">
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialogEditComments()" class="mr-2">
          Annuler
        </v-btn>
        <v-btn rounded color="blue" @click="saveEditComment()" class="mr-2">
          <v-icon>mdi-content-save</v-icon> Enregistrer
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showPopupDeleteComment" 
            max-width="750" :fullscreen="$vuetify.breakpoint.width < 600">
    <v-card color="black" v-if="deleteComment != null">
      <v-toolbar color="transparent" elevation="0">
        <v-icon color="red" class="mr-2">mdi-delete</v-icon> 
        Supprimer le commentaire
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialogDeleteComment()"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-divider/>

      <v-card-text class="pb-0 mt-5" id="">
        Voulez-vous vraiment supprimer ce commentaire ?<br><br>
        "<i>{{  deleteComment.text.substr(0, 300) }}{{ deleteComment.text.length > 300 ? '[...]' : '' }}</i>"
      </v-card-text>
      
      <br>
      <v-divider/>

      <v-toolbar color="transparent" elevation="0" height="80">
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialogDeleteComment()" class="mr-2">
          Annuler
        </v-btn>
        <v-btn rounded color="red" @click="saveDeleteComment()" class="mr-2">
          <v-icon>mdi-content-save</v-icon> Supprimer
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>

  </v-dialog>
</template>

<style>
  /* .toolbar-emo{
    opacity: 0 !important
  }
  .card-comment:hover .toolbar-emo{
    opacity: 1 !important
  } */
  .card-comment:hover{
    background-color: #050505 !important;
  }
  .v-card.newcom{
    background-color: #180202 !important;
    margin-top:4px;
  }

  .divider-newcom{
    border-color:#F44336 !important;
    margin-top:-1px;
  }
</style>

<script>

import axios from 'axios'
import router from '../../router/router'
import core from '../../plugins/core.js'

//import Post from '@/components/Post.vue'

import Gravatar from 'vue-gravatar'
import {TimeAgo} from "vue2-timeago"

export default {
  name: 'dialogcomments',
  components: { 'v-gravatar' : Gravatar , TimeAgo },
  props: ['refType'],
  data: () => ({
    refId: '',
    showPopupComments: false,
    showPopupEditComment: false,
    showPopupDeleteComment: false,
    comments: [],
    idCrewMsg: '',
    crewMsg: null,

    newComment: {
      text: '',
      refId: '',
      refType: ''
    },
    
    replyComment: null,
    editComment: null,
    deleteComment: null,

    nbComPending: 0,

    rules: {
      max: v => v.length <= 3000 || 'Max 3000 caractères',
    },
  }),
  async mounted(){
    this.$root.$off('openDialogComments').$on('openDialogComments', (data) => { 
      console.log("ON openDialogComments", data)
      this.comments = []
      //this.idCrewMsg = data.idCrewMsg
      this.refId = data.refId
      this.refType = data.refType
      this.newComment.refId = data.refId
      this.newComment.refType = data.refType
      this.showPopupComments = true
      this.fetchComments()

      //si le user affiche les commentaires de son propre status
      // if(this.refId == this.$store.state.auth.user._id) {
      //   this.nbComPending = this.$store.state.auth.user.statusNbCommentsPending
      //   this.$store.dispatch("auth/readStatus")
      // }
    })
    this.$root.$off('closePopupComments').$on('closePopupComments', () => { 
      this.closeDialog()
    })

    this.$root.$off('addComments').$on('addComments', (data) => { 
      console.log("addComments")
      if(this.showPopupComments == false) return 
      console.log("addComments", data)
      
      this.nbComPending = 1
      this.comments.push(data.comment)
      this.scrollCommentsList()
      this.$store.dispatch("auth/readStatus")
    })

  },
  methods: {
    closeDialog(){
      this.showPopupComments = false
    },
    async fetchComments(scroll=true){
      console.log("this.$store.state.auth.user.statusNbCommentsPending", this.$store.state.auth.user.statusNbCommentsPending)
      if(this.refId == this.$store.state.auth.user._id){
        this.nbComPending = this.$store.state.auth.user.statusNbCommentsPending
        this.$store.dispatch("auth/readStatus")
      } 

      let res = await axios.get("data/get-entity-comments/"+this.refType+"/"+this.refId)
      if(res.data.error == false){
        this.comments = res.data.comments
        if(scroll) this.scrollCommentsList()
      }
    },
    async sendComment(){
      let paramsComment = { refId: this.newComment.refId,
                            refType: this.newComment.refType,
                            text: this.newComment.text
                          }
      if(this.replyComment != null) 
        paramsComment.refComment = this.replyComment._id

      axios.post("comment/send-comment/", paramsComment)
            .then((res)=>{
              if(res.data.error == false){
                this.fetchComments()
                this.newComment.text = ""
                this.replyComment = null
              }
            })
    },
    scrollCommentsList(){
      setTimeout(()=>{
        let scroller = document.getElementById("commentscroll")
        let height = scroller.scrollHeight
        scroller.scroll(0, height)
      },100)
    },
    getReactionIcon(reactionName){
      let icon = ""
      this.$store.state.app.emoReactions.forEach((emo)=>{
        if(emo.name == reactionName) icon = emo.icon
      })
      return icon
    },
    getReactionColor(reactionName){
      let color = ""
      this.$store.state.app.emoReactions.forEach((emo)=>{
        if(emo.name == reactionName) color = emo.color
      })
      return color
    },
    async toogleReaction(commentId, reactionName){
      axios.post("comment/toogle-reaction/", 
              { commentId: commentId,
                reactionName: reactionName
              })
            .then((res)=>{
              if(res.data.error == false){
                //remplace l'ancien commentaire par le nouveau
                //pour actualiser le nombre de réactions
                this.comments.forEach((comment, i)=>{
                  if(comment._id == commentId) 
                    this.comments[i] = res.data.comment
                })
                this.$forceUpdate()
              }
            })
    },
    startReply(comment){
      this.replyComment = comment 
    },

    /* EDIT COMMENT */
    startEdit(comment){
      this.editComment = comment
      this.editComment.textEdit = comment.text
      this.showPopupEditComment = true
    },
    closeDialogEditComments(){
      this.editComment = null
      this.showPopupEditComment = false
    },
    saveEditComment(){
      axios.post("comment/save-edit/", 
              { commentId: this.editComment._id,
                commentText: this.editComment.textEdit
              })
            .then((res)=>{
              if(res.data.error == false){
                //remplace l'ancien commentaire par le nouveau
                this.comments.forEach((comment, i)=>{
                  if(comment._id == this.editComment._id) 
                    this.comments[i] = res.data.comment
                })
                this.closeDialogEditComments()
                this.$forceUpdate()
                this.$store.dispatch('app/openSnackbar',  { show: true, color:'blue', 
                                                            title: "Commentaire modifié",
                                                            text: "", icon: 'pencil'  })
              }
            })
    },

    /* DELETE COMMENT */
    startDelete(comment){
      this.deleteComment = comment
      this.showPopupDeleteComment = true
    },
    closeDialogDeleteComment(){
      this.deleteComment = null
      this.showPopupDeleteComment = false
    },
    saveDeleteComment(){
      axios.post("comment/save-delete/", { commentId: this.deleteComment._id })
            .then((res)=>{
              if(res.data.error == false){
                this.fetchComments(false)
                this.closeDialogDeleteComment()
                this.$forceUpdate()
                this.$store.dispatch('app/openSnackbar',  { show: true, color:'red', 
                                                            title: "Commentaire supprimé",
                                                            text: "", icon: 'delete' })
              }
            })
    },
    openUserPage(userId){
      this.closeDialog()
      router.push('/user/' + userId)
    },
    nl2br: function(str){
      return core.completeText(str, null)
    },
  },
  computed: {
    
    
  }
}
</script>
