<template>
  <v-dialog  :fullscreen="$vuetify.breakpoint.width < 600"
              v-model="showDialog" max-width="650">
    <v-card color="" min-height="200">
      <v-toolbar elevation="0" color="" class="primary--text" dense>
        <v-icon class="mr-1" color="primary">mdi-thought-bubble-outline</v-icon> 
        <b>Actu<span class="d-none d-sm-inline">alité</span> de votre status</b>
        <v-spacer/>
        <v-btn x-small text color="primary" :loading="$store.state.map.streamLoading" 
              class="btn-close-bar btn-refresh-bar black--text" style=""
              fab @click="refreshData()">
          <v-icon color="">mdi-refresh</v-icon>
        </v-btn>
        <v-btn @click="showDialog = false" 
                 icon small color="primary" class="mr-0">
                <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
  
      <v-col cols="12" class="px-5">

        <v-row class="my-0">
          <v-toolbar v-if="$store.state.auth.user.currentMsg.length > 0"
                     elevation="0" color="transparent" class="mb-2" dense> 
            
              <v-btn elevation small rounded class="mr-2 black--text" color="primary" 
                      v-if="$store.state.auth.user.statusNbCommentsPending > 0"
                      @click="$root.$emit('openDialogComments', { refId: $store.state.auth.user._id, refType: 'status' })">
              
                <v-icon small color="black">mdi-bell</v-icon>

                <v-badge color="red" class="" 
                          :value="$store.state.auth.user.statusNbCommentsPending" 
                          :content="$store.state.auth.user.statusNbCommentsPending">
                    <span class="d-none d-sm-inline ml-1">Non-lu{{ $store.state.auth.user.statusNbCommentsPending > 1 ? 's' : '' }} </span>
                </v-badge>
              </v-btn>
              <template v-else>
                <small class="green--text d-none d-sm-inline"
                       @click="$root.$emit('openDialogComments', { refId: $store.state.auth.user._id, refType: 'status' })">
                  <v-icon small color="green" class="mr-1">mdi-check</v-icon>
                  Aucun nouveau commentaire
                </small>
              </template>

            <v-spacer></v-spacer>
            <v-btn elevation rounded class="mr-2" @click="deleteStatus()">
              <v-icon small class="mr-1">mdi-close</v-icon> 
              <span class="d-none d-sm-inline">Effacer</span>
            </v-btn>
            <v-btn elevation rounded class="" @click="$root.$emit('openDialogEditStatus')">
              <v-icon small class="mr-1">mdi-pencil</v-icon> 
              <span class="d-none d-sm-inline">Éditer</span>
            </v-btn>
          </v-toolbar>
        </v-row>
        
        <template v-if="$store.state.auth.user.statusNbCommentsPending == 0">
          <small class="green--text d-sm-none d-block pl-5 pb-4"
                  @click="$root.$emit('openDialogComments', { refId: $store.state.auth.user._id, refType: 'status' })">
            <v-icon small color="green" class="mr-1">mdi-check</v-icon>
            Aucun nouveau commentaire
          </small>
        </template>
        
        <Post v-if="$store.state.auth.user.currentMsg.length > 0" :entity="$store.state.auth.user"></Post>
        <v-card class="mt-5" v-else outlined>
          <v-toolbar color="transparent" elevation="0">
          Votre status est vide.
            <v-spacer></v-spacer>
            <v-btn rounded color="primary" class="black--text" @click="$root.$emit('openDialogEditStatus')">
              <v-icon class="" color="black">mdi-thought-bubble</v-icon> 
              <v-icon class="" color="black" small>mdi-pencil</v-icon> 
              Éditer mon status
            </v-btn>
          </v-toolbar>
        </v-card>
        
      </v-col>
  
    </v-card>

  </v-dialog>
  </template>
  
  <style></style>
  
  <script>
  
  //import axios from 'axios'
  //import router from '../router/router'
  //import core from '../plugins/core.js'
  
  import Post from '@/components/stream/Post.vue'

  export default {
    name: 'empty',
    components: { Post },
    data: () => ({
      showDialog: false,
      msg: "",
      loading: false,
      rules: {
            max: v => v.length <= 1000 || 'Max 1000 caractères',
          },
    }),
    async mounted(){      
      this.$root.$off('openDialogMyStatus')
                .$on('openDialogMyStatus', async () => { 
        console.log("on openDialogMyStatus")
        this.showDialog = true
      })
      this.$root.$off('closeDialogMyStatus')
                .$on('closeDialogMyStatus', async () => { 
        //console.log("on closeDialogMsgPerso")
        this.showDialog = false
      })
    },
    methods: {
      async refreshData(){
        this.$store.dispatch('auth/refreshUserData')
      },
      deleteStatus(){
      this.loading = true
      this.$store.dispatch("auth/saveUserCurrentMsg", "")
                 .then(()=>{
                  //console.log("auth/saveUserCurrentMsg ?", res)
                    this.showDialog = false
                    this.loading = false
                    this.$store.dispatch('app/openSnackbar', 
                                        { show: true, color:'green', 
                                          title: "Votre message perso a été effacé",
                                          text: '', // this.$store.state.auth.user.currentMsg,
                                          icon: 'thumb-up' })
                  
                 })
    }
  
    },
    computed: {
      
      
    }
  }
  </script>
  