<template>
  <div>
    <!-- TOP BAR -->
    <v-app-bar app :hide-on-scroll="false" elevation="0" color="" id="top-bar">
        
        <v-btn text elevation="0" icon title="Menu" class="mr-1"
              @click.stop="$store.dispatch('app/setDrawerOpen', !$store.state.app.drawerOpen)" >
          <v-img src="../../assets/img/monnaie_pirate_logo.png" width="20" style="margin:4px 5px 4px 4px;"/>
        </v-btn>

        <router-link to="/inbox-crew" v-if="$store.state.auth.user.inBFC" class="d-none d-sm-flex mx-2">
          <v-img src="../../assets/img/black_fire_crew-600.png" width="58" :class="notifyInboxCrew ? 'notifyInboxCrew' : ''"/>
        </router-link>

        
        <!-- <v-btn fab icon small elevation="0" v-if="!$store.state.auth.isLogin"
              title="Menu principal" class="mx-1" to="/">
          <v-icon>mdi-home</v-icon>
        </v-btn> -->
        
        <v-btn fab icon small elevation="0"
              title="Menu principal" class="mx-1 d-none d-md-flex" to="/dashboard">
          <v-icon>mdi-view-dashboard</v-icon>
        </v-btn>

        <v-btn fab icon small to="/game/battle/menu" 
               elevation="0" class="mx-1 d-none d-md-flex"  title="Jouer à la bataille navale">
          <v-icon>mdi-sail-boat</v-icon>
        </v-btn>

        <template v-if="showMenuComplete && !$store.state.auth.isLogin">
          <template v-for="(item) in itemsMenu">
            <v-btn :key="item.name" 
                    v-if="item.side == 'left'" 
                    :to="item.to" class="mx-1 d-none d-md-flex"
                    text rounded>
                    <v-icon small>{{ item.icon }}</v-icon>
                    {{ item.label }}
            </v-btn>
          </template>
        </template>

        <v-btn v-if="$store.state.auth.isLogin" fab icon small
              class="mx-1 d-none d-md-flex" to="/market" title="Marché pirate">
            <v-icon>mdi-cart-arrow-right</v-icon> 
        </v-btn>
        <v-btn v-if="$store.state.auth.isLogin"
              class="mx-1 d-none d-md-flex" fab icon small title="Créer une annonce"
              :to="$store.state.auth.isLogin ? '/publish-offer' : '/login'">
            <v-icon>mdi-file-plus</v-icon> 
        </v-btn>
        <v-btn v-if="$store.state.auth.isLogin" fab icon small
              class="mx-1 d-none d-md-flex" to="/map" title="La carte">
            <v-icon>mdi-map-search</v-icon> 
        </v-btn>
        <v-btn v-if="!$store.state.auth.isLogin" fab icon small
              class="mx-1 d-none d-md-flex" to="/book" title="Consulter le Journal de bord">
            <v-icon>mdi-book-open-page-variant-outline</v-icon> 
        </v-btn>
        <v-btn v-if="!$store.state.auth.isLogin" fab icon small
              class="mx-1 d-none d-md-flex" to="/code-des-pirates" title="Consulter le Code des Pirates">
            <v-icon>mdi-feather</v-icon> 
        </v-btn>
       

        <v-btn v-if="$store.state.auth.isLogin" fab icon small
              class="mx-1 d-none d-md-flex" to="/recruit" title="Parrainage">
            <v-icon small>mdi-qrcode</v-icon> 
        </v-btn>
       
        <v-spacer/> 

        <template v-if="showMenuComplete && !$store.state.auth.isLogin">
            <template v-for="(item) in itemsMenu">
              <v-btn  :key="item.label" v-if="item.side == 'right'" 
                      :to="item.to" text class="mr-2">
                      {{ item.label }}
              </v-btn>
          </template>
        </template>

        <v-btn v-if="nbNotifSeller > 0" x-small class="mr-2" fab color="transparent" to="/propositions/seller">
          <v-badge color="red" :content="nbNotifSellerHot" :value="nbNotifSellerHot">
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
        
        <v-btn v-if="nbNotifBuyer > 0" x-small class="mr-2" fab color="transparent" to="/propositions/buyer">
          <v-badge color="red" :content="nbNotifBuyerHot" :value="nbNotifBuyerHot">
            <v-icon>mdi-basket</v-icon>
          </v-badge>
        </v-btn>

        <!-- Total : {{ (convertUnityMony($store.getters['auth/totalDepositMony'] * monyToEuro())).toFixed(2) }} €
        ~{{ ($store.getters['auth/amountCurrent'] * monyToEuro()).toFixed(2) }} € -->
        <span style="opacity:0;display:none">{{ $store.state.auth.lastMsgReceived }}</span>
      
        <v-btn v-if="$store.state.auth.isLogin" icon class="mx-1 d-none d-md-inline" fab small @click="openDialogMyStatus()" title="Mon status">
          <v-badge color="red" class="" :value="nbNotifStatus" :content="nbNotifStatus">
            <v-icon :color="nbNotifStatus > 0 ? 'primary' : ''">mdi-thought-bubble</v-icon>
          </v-badge>
        </v-btn>


        <v-btn v-if="$store.state.auth.isLogin" title="Discussions privées" 
              @click="$store.dispatch('app/setLiveMenuOpen', true)"
              :class="$store.getters['auth/privateMsgNotRead'] > 0 ? 'mr-4' : ''"
              :color="$store.getters['auth/privateMsgNotRead'] > 0 ? 'red' : 'blue'" 
              small class="mr-1 d-lg-none" elevation="0" icon fab>
          <v-badge :content="$store.getters['auth/privateMsgNotRead']" color="red"
                   :value="$store.getters['auth/privateMsgNotRead']">
            <v-icon>mdi-message-outline</v-icon>
          </v-badge>
        </v-btn>

        <v-chip dense v-if="$store.state.auth.isLogin" color="secondary" 
                :to="'/wallet/'+$store.state.auth.user.walletMain.uid"
              class="mr-3 pl-0 pr-1 font-led primary--text" style="font-size:20px;">
              
          <v-img src="../../assets/img/monnaie_pirate_logo.png" width="25" style="margin:4px 5px 4px 4px;"/>
          <b class=" mr-1 mt-1">{{ $store.getters['auth/amountTotalCurrent'].toFixed(2) }}</b> 
          <small class=" mt-1 mr-1">{{ $store.state.app.monyUnity }}</small> 
          <v-img v-if="$store.state.auth.user.planPaid" :src="baseUrl() + $store.state.auth.user.imgBadgeCoin" width="25" class="img-circle"/>
        </v-chip>

        <template v-if ="!$store.state.auth.isLogin && enableRegisterUser">
          <!-- <v-btn class="mr-2" text to="/register">
              <v-icon>mdi-account-plus</v-icon> S'inscrire
          </v-btn> -->
          <v-btn class="mr-0 d-md-none" icon to="/login">
              <v-icon>mdi-login</v-icon>
          </v-btn>
          <v-btn class="mr-0 d-none d-md-flex" text to="/login">
              <v-icon>mdi-login</v-icon><span> Se connecter</span>
          </v-btn>
        </template>

        <v-menu v-if="$store.state.auth.isLogin" open-on-hover  
              offset-y left :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="$store.state.auth.isLogin" rounded small text @click="$store.dispatch('auth/refreshUserData')"
                    class="d-none d-sm-inline-flex mr-2 pl-1" v-on="on" v-bind="attrs">
              <v-gravatar height="22" style="border-radius:50%;"
                          :default-img="'retro'" :email="$store.state.auth.user.name"/>
              <span class="ml-1">{{ $store.state.auth.user.name }}</span>
            </v-btn>
            <v-btn v-if="$store.state.auth.isLogin" rounded small icon class="d-sm-none mr-0" v-on="on" v-bind="attrs">
              <v-gravatar height="28" style="border-radius:50%;"
                          :default-img="'retro'" :email="$store.state.auth.user.name"/>
            </v-btn>
          </template>

          <v-list dense>
              <v-list-item to="/profil">
                <v-gravatar height="22" style="border-radius:50%; margin-left:-3px;"
                            :default-img="'retro'" :email="$store.state.auth.user.name"/>
                <span class="ml-1">Mon profil - <b>{{ $store.state.auth.user.name }}</b></span>
              </v-list-item>
              <template v-for="company in $store.state.auth.user.companies">
                <v-list-item :key="company._id" :to="'/company/'+company._id">
                  <v-gravatar height="22" style="border-radius:50%; margin-left:-3px;"
                              :default-img="'retro'" :email="company.name"/>
                  <span class="ml-1"><b>{{ company.name }}</b></span>
                </v-list-item>
              </template>
              <v-list-item icon to="/config" class="">
                  <v-icon class="mr-2" small>mdi-cog</v-icon> Paramètres
              </v-list-item>
              <v-list-item icon to="/step/geoloc" class="">
                  <v-icon class="mr-2" small>mdi-target</v-icon> Position principale
              </v-list-item>
              <v-divider/>
              <v-list-item icon :to="'/wallet/'+$store.state.auth.user.walletMain.uid" class="">
                  <v-icon class="mr-2" small>mdi-wallet</v-icon> Mes comptes
              </v-list-item>
              <v-divider/>
              <v-list-item icon to="/my-offers" class="">
                  <v-icon class="mr-2" small>mdi-offer</v-icon> Mes annonces
              </v-list-item>
              <v-list-item icon to="/publish-offer" class="">
                  <v-icon class="mr-2" small>mdi-plus</v-icon> Publier une annonce
              </v-list-item>
              <v-list-item icon to="/propositions/buyer" class="">
                  <v-icon class="mr-2" small>mdi-chevron-double-right</v-icon> Propositions envoyées
              </v-list-item>
              <v-list-item icon to="/propositions/seller" class="">
                  <v-icon class="mr-2" small>mdi-chevron-double-left</v-icon> Propositions reçues
              </v-list-item>
              <v-divider/>
              <v-list-item icon to="/create-company" class="">
                  <v-icon class="mr-2" small>mdi-briefcase-plus-outline</v-icon> Référencer mon activité
              </v-list-item>
              <v-divider/>
              <v-list-item icon @click="logout" class="">
                  <v-icon class="mr-2" small>mdi-logout</v-icon> Déconnexion
              </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y v-if="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs"
              elevation="0" height="32px" 
              class="mx-1 pa-3 rounded-lg font-weight-bold d-none d-md-flex"
            >
              <img class="mr-2" :src="currentLanguage.flagUrl" height="20px" /> 
              <!-- <span style="text-transform:uppercase;font-weight:700;">
                {{ currentLanguage.code }}
              </span> -->
              <v-icon small class="ml-">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list dense>

            <language-switcher v-slot="{ links }">
              <v-list-item :to="link.url" v-for="link in links" @click="changeLocale()" :key="link.langIndex">
                <img :src="require(`@/assets/flag/${link.langIndex}.png`)" alt="" class="mr-3" height="20px"/>
                <span>{{ link.langName }}</span>
              </v-list-item>
            </language-switcher>

          </v-list>
        </v-menu>


    <template 
      v-slot:extension
      v-if="showMenuExt"
    >
      <v-tabs 
        class="tab-wallets" elevation="0" centered
        color="primary"
        :show-arrows="$route.path.indexOf('market') == -1"
        hide-slider
        align-with-title
        center-active 
      >

      <!-- <template v-if="$route.path.indexOf('wallet') > -1 && $store.state.auth.isLogin">
        <v-tab class="submenu-link" :to="'/wallet/' + $store.state.auth.user.walletMain.uid">
          <v-icon small class="mr-1">mdi-credit-card-clock</v-icon> Compte courant
        </v-tab>
        
        <template v-for="(walletD, i) in $store.state.auth.user.walletsDeposit">
          <v-tab class="submenu-link" :to="'/wallet/' + walletD.uid" :key="i">
            <v-icon small class="mr-1">mdi-credit-card-settings</v-icon>
            <template v-if="walletD.name == 'DEPOSIT'">Compte de dépot</template>
            <template v-else>{{ walletD.name }}</template>
          </v-tab>
        </template>
      </template> -->

      <template v-if="$route.path.indexOf('market') > -1">
        <v-tabs fixed-tabs height="38" 
              class="d-none d-md-inline"
              color="primary"
              style="margin:5px; max-width:33%;"
              show-arrows
              hide-slider
              align-with-title>

          <v-tab :class="$store.state.market.requestParams.offerType=='OFFER' ? 'light-blue--text' : 'grey--text'" 
                  @click="$root.$emit('setTypeSearch', 'OFFER')">Offres</v-tab>

          <v-tab :class="$store.state.market.requestParams.offerType=='DEMANDE' ? 'light-blue--text' : 'grey--text'" 
                  @click="$root.$emit('setTypeSearch', 'DEMANDE')">Demandes</v-tab>
        </v-tabs>
        
        
        <v-text-field dense solo hide-details class="text-search-offer" min-height="35"
                      placeholder="que recherchez-vous ?" clearable v-model="searchOfferTxt"
                      append-icon="mdi-refresh" flat color="primary"
                      @change="$root.$emit('fetchOffers', searchOfferTxt)"
                      @keyup.enter="$root.$emit('fetchOffers', searchOfferTxt)"
                      @click:append="$root.$emit('fetchOffers', searchOfferTxt)">
        </v-text-field>
        <v-tab class="submenu-link" :disabled="!$store.state.auth.user.isActive"
              :to="$store.state.auth.isLogin ? '/publish-offer' : '/register'" 
              color="orange" style="font-weight:300;">
          <v-icon color="orange" x-small class="mr-1">mdi-pencil</v-icon> 
          <b class="orange--text">Publier</b>
        </v-tab>
      </template>

      </v-tabs>
    </template>


    </v-app-bar>
  </div>
</template>

<style>
/* #top-bar{
  border-bottom:1px solid #8e8e8e42 !important;
}
#top-bar .v-toolbar__extension{
  border-top:1px solid #8e8e8e42 !important;
} */
.main-logo-topbar .v-btn__content{
  font-size: 27px!important;
  font-weight:800!important;
  margin-top:-3px;
}
.text-search-offer {
    max-width: 56.5% !important;
    /* padding-left: 33.5% !important; */
    margin-top: 5px !important;
    margin-bottom: 4px !important;
    margin-left: 6px !important;
    margin-right: 6px !important;
}
@media (max-width: 1275px) {
  .text-search-offer {    
      /* max-width: 90% !important;
      padding-left: 34% !important; */
      margin-top: 5px !important;
      margin-bottom: 4px !important;
  }
}
@media (max-width: 960px) {
  .text-search-offer {
      max-width: unset!important;
      padding: 0 !important;
      margin-top: 4px !important;
      margin-bottom: 4px !important;
  }
  .main-logo-topbar .v-btn__content{
    font-size: 18px !important;
    padding-bottom: 3px;
    margin-top:0px;
  }
  .v-slide-group__content{
    transform: translateX(0px) !important;

  }
}

@media (max-width: 400px) {
  .tab-wallets .v-slide-group__prev{
    display: none!important;
  }
  .text-search-offer {
      max-width: 45%!important;
      width: 45%!important;
  }
}
</style>

<script>

import core from '../../plugins/core.js'
import router from '../../router/router'
import Gravatar from 'vue-gravatar'
const config = require('../../config/' + process.env.NODE_ENV)

export default {
  name: 'top-bar',
  components: {
    'v-gravatar' : Gravatar,
  },
  props: [ 'showMenuComplete' ],
  data: () => ({
    itemsMenu: [
      //{ side: 'left',  label: 'Concept',          to: '/concept',     icon: 'mdi-lightbulb'     },
      // { side: 'left',  label: 'La monnaie',       to: '/mony',        icon: 'mdi-wallet'        },
      // { side: 'left',  label: 'FAQ',              to: '/faq',         icon: 'mdi-cloud-question' },
      //{ side: 'left',  label: '',              to: '/',             icon: 'mdi-home' },
      //{ side: 'left',  label: 'Jouer',         to: '/game/battle/menu',         icon: 'mdi-sail-boat' },

      //{ name: 'Admin',        side: 'right', label: 'Admin',     to: '/admin',   icon: 'mdi-account-cowboy-hat' },
    ],
    languages : [
      { label: "Fr", value: "fr" },
      { label: "En", value: "en" },
      { label: "Es", value: "es" },
    ],
    currentLang: 'fr',
    searchOfferTxt: "",
    menuExtRoutAllowed: ["market"]
  }),
  
  mounted: function(){
    this.$vuetify.lang.current = 'fr'
  },
  
  methods: {
    baseUrl(){ return core.baseUrl() },
    logout(){
      this.$store.dispatch('auth/logout')
    },
    onClickLogo(){ 
      if(this.$store.state.auth.isLogin) router.push('/market') 
      else router.push('/') 
    },
    changeLocale () {
      // if(lang!=null) this.local = lang
      // this.$vuetify.lang.current = this.local
      // localStorage.setItem("lang", this.local)

      this.$root.$emit('langChanged')
      
    },
    convertUnityMony(amountUnity){
      return core.convertUnityMony(amountUnity, this.$store.state.auth.monyConvertValue)
    },
    convertMonyUnity(amountMony){
      return core.convertMonyUnity(amountMony, this.$store.state.auth.monyConvertValue)
    },
    monyToEuro(){
      return config.monyToEuro
    },
    switchTheme(){
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem("themeDark", this.$vuetify.theme.dark)
    },
    openDialogMyStatus(){
      this.$root.$emit("openDialogMyStatus")
    }
  },
  computed:{
    adminClientUrl() { return config.adminClientUrl },
    enableRegisterUser() { return config.enableRegisterUser },
    currentLanguage() {
      return {
        code: this.$i18n.locale,
        flagUrl: require(`../../assets/flag/${this.$i18n.locale}.png`)
      }
    },
    nbNotifSeller(){
      if(this.$store.state.auth.isLogin == false) return
      let listAll = this.$store.getters['auth/myPropositionsReceived']
      console.log("nbNotifSeller", listAll.length)
      return listAll.length
    },
    nbNotifSellerHot(){
      if(this.$store.state.auth.isLogin == false) return
      let len = this.$store.getters['auth/myPropositionsReceivedNbNotif']
      console.log("nbNotifSellerHot", len)
      return len
    },
    nbNotifBuyer(){
      if(this.$store.state.auth.isLogin == false) return
      let listAll = this.$store.getters['auth/myPropositionsSent']
      return listAll.length
      
    },
    nbNotifBuyerHot(){
      if(this.$store.state.auth.isLogin == false) return
      let listAll = this.$store.getters['auth/myPropositionsSentNbNotif']
      return listAll
      
    },
    
    showMenuExt(){
      let show = false 
      this.menuExtRoutAllowed.forEach((route)=>{
        if(this.$route.path.indexOf(route) > -1) show = true
      })
      return show
    },
    notifyInboxCrew(){
      return false // this.$store.state.app.inboxCrew...
    },
    nbNotifStatus(){ return this.$store.state.auth.user.statusNbCommentsPending }
  },
  
  watch: {
    
      
      '$store.state.market.requestParams.search': { immediate: true, 
          async handler (){ 
            //if(this.$store.state.market.requestParams.search == null) 
              this.searchOfferTxt = this.$store.state.market.requestParams.search
          }
      },
      'searchOfferTxt': { immediate: true, 
          async handler (){ 
            if(this.searchOfferTxt == null) this.searchOfferTxt = ""
          }
      },
  }, 
  
};
</script>
