<template>
  <div class="status-bar d-none">
      <!-- TOP BAR -->
      
        <v-btn  height="46" color="primary" class="mx-1" title="Gérer mon status">
            <v-icon color="">mdi-thought-bubble-outline</v-icon> 
        </v-btn>
  </div>
  </template>
      
  <script>
  export default {
  
  }
  </script>
  
  <style>
  
  </style>