<template>
  <v-card elevation="0" height="40" class="badge-pirate text-center" :to="'/pay'" 
          v-if="$store.state.auth.isLogin && !$store.state.auth.user.planPaid">
      <div class="d-inline-flex">
      <v-img src="../../assets/img/coin-badge.jpg" style="border-radius:100%;" width="26" height="26"/>
      <small class="font-papyrus mt-1 mx-2 txt-badge">
        Devenir membre d'équipage
      </small>
      <v-img src="../../assets/img/coin-badge.jpg" class="d-md-none" style="border-radius:100%;" width="26" height="26"/>
      </div>
  </v-card>
</template>

<style>
</style>


<script>

export default {
    name: "badge",
    components: {
      
    },
    props: ['post'],
    data: () => ({
    }),
    mounted: function(){
    },
    methods: {
        
        //baseUrl(){ return core.baseUrl() },
        
    },
    computed:{
      
    }
};
</script>