const config = require('../config/' + process.env.NODE_ENV)

import moment from 'moment'
//import axios from "axios"
//import { i18n } from 'vue-lang-router';
// import store from '../store'

let core = {
    baseUrl: function(){
        return config.root_node.url + ":" + config.root_node.port + "/"
    },
    convertMonyUnity: function(amountMony, monyConvertValue){
        return amountMony * monyConvertValue
    },
    convertUnityMony: function(amountUnity, monyConvertValue){
        if(monyConvertValue == 0) return 0
        return amountUnity / monyConvertValue
    },
    getDistance :function(origin, destination) {
        // return distance in meters
        var lon1 = core.toRadian(origin[1]),
            lat1 = core.toRadian(origin[0]),
            lon2 = core.toRadian(destination[1]),
            lat2 = core.toRadian(destination[0]);
    
        var deltaLat = lat2 - lat1;
        var deltaLon = lon2 - lon1;
    
        var a = Math.pow(Math.sin(deltaLat/2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon/2), 2);
        var c = 2 * Math.asin(Math.sqrt(a));
        var EARTH_RADIUS = 6371;
        return (c * EARTH_RADIUS).toFixed(0)
    },
    toRadian: function(degree) {
        return degree*Math.PI/180;
    },
    getCountDay(post){
        let d = moment(post.dateStory)
        let now = moment(new Date('07/14/2022'))
        //console.log("date", d, '!', now, now.diff(d, 'days'))
        return now.diff(d, 'days')
    },
    getStrDate(d){
      if(d == null) return "null"
      let date = new Date(d)
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
      let month = date.getMonth() < 9 ? "0" + (date.getMonth()+1) : (date.getMonth()+1)
      return day + "/" + month + "/" + date.getFullYear()
    },

    completeText: function(txt, hashtags, nl2brX=true){
        if(txt == null) return ""

        txt = txt.replace(/<[^>]*>/g, '');

        let allLinks = txt.match(/\bhttps?:\/\/\S+/gi)

        if(allLinks != null)
        allLinks.forEach((link) => {
           let l = link
           if(link.indexOf('fbclid=') > -1) link = link.slice(0, link.indexOf('fbclid=')-1)
           txt = txt.replace(l, link) 
           txt = txt.replace(link, '<a href="'+link+'" target="_blank" rel="noopener">'+link+'</a>')
        })
        
        let regex = /(^|\s)(@[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ_\d-]+)/ig
        let mentions = txt.match(regex);
        //console.log("ccompleteText", mentions)
        if(mentions != null && mentions.length > 0)
        txt = txt.replaceAll(regex, ' <span class="span-hashtag">$&</span>')
        
        if(nl2brX) txt = core.nl2br(txt)
        return txt
    },
    nl2br: function(str, is_xhtml){
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'
        let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
        return newStr
    },
}

export default core