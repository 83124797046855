<template>
  <v-app>
    
    <v-main>
      <v-container class="pa-0" id="main-container" fluid>
        <iframe width="100%" :height="height" src="https://lamonnaiepirate.net"></iframe>
      </v-container>
    </v-main>

  </v-app>
</template>

<style>
  iframe{
    border:0px !important;
  }
</style>

<script>

export default {
  name: 'AppIframe',
   
  components: {
    
  },

  mounted: function(){
    
  },

  data: () => ({
    
  }),

  methods: {
    
  }, 
  computed: {
    height(){
      return window.screen.availHeight;
    }
  }, 

};
</script>
