
import axios from "axios"
import { i18n } from 'vue-lang-router'
import moment from 'moment'

const config = require('../../config/' + process.env.NODE_ENV)

const state = () => ({
  isLoading: true,
  drawerOpen: false,
  liveMenuOpen: false,

  data: { 
    walletMain : [],
    offer : [],
    offerCategory : [],
    question: [],
    plan: [],
    companyCategory : [],
    blogPost: [],
    blogCategory: []
  },

  publicWallets: [],

  langAvailables : [],

  snackbar: {
    show: false,
    title: "",
    text: "",
    color: "",
    icon: "",
    path: null,
    dispatchAction: null,
    dispatchParams: null
  },

  showDialogMailDev: false,
  dialogMailDev: null,

  showDialogConv: false,
  currentConv: null,

  currentPrivateConvUserName: null,
  siteName: "La monnaie pirate",
  monyUnity: "PRT",
  nbUsersTotal: 0,
  limitForFreePlan: 0,

  clock: null,
  clockerD: '',
  clockerH: '',
  clockerM: '',
  clockerS: '',


  emoReactions: [
    { name: 'like',       icon: 'thumb-up',                   color: 'green' },
    { name: 'dislike',    icon: 'thumb-down',                 color: 'red' },
    { name: 'happy',      icon: 'emoticon-outline',           color: 'teal' },
    { name: 'xd',         icon: 'emoticon-lol-outline',       color: 'cyan' },
    { name: 'cry',        icon: 'emoticon-cry-outline',       color: 'blue' },
    { name: 'cool',       icon: 'emoticon-cool-outline',      color: 'yellow' },
    { name: 'sad',        icon: 'emoticon-sad-outline',       color: 'orange' },
    { name: 'devil',      icon: 'emoticon-devil-outline',     color: 'red' },
  ], 
  
});

const actions = {
  setDrawerOpen({ commit }, open) {
    commit("setDrawerState", open)
  },
  setLiveMenuOpen({ commit }, open) {
    commit("setLiveMenuOpen", open)
  },
  setAppLoading({ commit }, isLoading) {
    commit('setLoadingState', isLoading)
  },
  async initLang({ commit }) {
    const { data } = await axios.post('/data/get-lang-availables')
    commit('initLang', data.languages)
  },
  async fetchNbUsersTotal({ commit }) {
    const { data } = await axios.post('/data/get-nb-users-total')
    commit('setNbUsersTotal', data.nbUsers)
    commit('setLimitForFreePlan', data.limitForFreePlan)
  },
  openSnackbar({ commit }, params) {
    commit("openSnackbar", params)
  },

  async fetchEntities({ commit }, params){
    //console.log("fetchEntities", params.entityType, state.data[params.entityType].length, params.limit)
    //si on a déjà chargé un type, on ne recommence pas
    //if(state.data[entityType].length > 0) return
    //si on en a aucune, on les charge toutes
    const { data } = await axios.post('/data/query-entities', { entityType: params.entityType, 
                                                                lang: i18n.locale, 
                                                                limit: params.limit, 
                                                                query: params.query, 
                                                                sort: params.sort })

    if (data.error === false) {
      commit("setEntities", { 'entities' : data.entities, 'entityType' : params.entityType })
      return
    }
  },
  async fetchPublicWallets({ commit }){
    //console.log("fetchEntities", params.entityType, state.data[params.entityType].length, params.limit)
    //si on a déjà chargé un type, on ne recommence pas
    //if(state.data[entityType].length > 0) return
    //si on en a aucune, on les charge toutes
    let publicWallets  = await axios.get('/data/get-public-wallets')
    console.log("fetchPublicWallets", publicWallets.data.entities)
    commit("setPublicWallets", publicWallets.data.entities)
  },
  setCurrentPrivateConvUserName({ commit }, name){
    console.log("setCurrentPrivateConvUserName", name)
    commit("setCurrentPrivateConvUserName", name)
  },
  openDialogMailDev({ commit }, params) {
    //console.log("process.env.NODE_ENV", process.env.NODE_ENV)
    if(config.emailMode != "production") /* process.env.NODE_ENV */ 
      commit("openDialogMailDev", params)
  },
  closeDialogMailDev({ commit }, params) {
    commit("closeDialogMailDev", params)
  },
  async incStat({ commit }, url){
    //console.log("incStat", config.domaineName, url)
    axios.post('/stat/inc-stat-url', { domaineName: config.domaineName, url: url })
    commit('statSent', false)
  },
  setShowDialogConv({ commit, rootState }, params) {
    params.rootConvs = rootState.auth.conversations
    commit("setShowDialogConv", params)
  },

  openConv({ commit, rootState }, params){
    console.log("openConv", params)
    
    if(rootState.auth.user._id == params.userId) return

    let urlParams = params.userId
    urlParams += params.companyId != null ? "/" + params.companyId : ""
    axios.get('/private/get-conversation/' + urlParams)
         .then((res)=>{
           console.log('/private/get-conversation/' + urlParams, 'OK', res.data)
           if(res.data.error == false){
            let params = { show: true, rootConvs: 
                            rootState.auth.conversations, 
                            conv: res.data.conversation }
            commit("setShowDialogConv", params)
           }
           return !res.data.error

          })
  },

  startClocker({ commit }, params) {
    commit("startClocker", params)
  },
};

const mutations = {
  setDrawerState(state, open) {
    state.drawerOpen = open;
  },
  setLiveMenuOpen(state, open) {
    state.liveMenuOpen = open;
  },
  setLoadingState(state, isLoading) {
    state.isLoading = isLoading;
  },
  setEntities(state, data){
    state.data[data.entityType] = data.entities
  },
  initLang(state, languages){
    state.langAvailables = languages
  },
  setNbUsersTotal(state, nbUsers){
    state.nbUsersTotal = nbUsers
  },
  setLimitForFreePlan(state, limitForFreePlan){
    state.limitForFreePlan = limitForFreePlan
  },
  openSnackbar(state, params) {
    console.log("openSnackbar", params)
    state.snackbar = params
    if(params.show == false){ 
      state.snackbar.text = ""
      state.snackbar.title = ""
      state.snackbar.path = null
      state.snackbar.icon = null
      state.snackbar.dispatchAction = null
      state.snackbar.dispatchParams = null
    }
  },
  setCurrentPrivateConvUserName(state, name){
    state.currentPrivateConvUserName = name
  },
  openDialogMailDev(state, params) {
    state.dialogMailDev = params
    state.showDialogMailDev = true
  },
  closeDialogMailDev(state) {
    state.showDialogMailDev = false
  },
  statSent(sent) {
    state.isLoading = sent
  },
  setShowDialogConv(state, params) {
    let conv = params.conv
    if(typeof params.conv == "string"){
      params.rootConvs.forEach((conv2)=>{
        if(conv == conv2._id) conv = conv2
      })
    }
    state.showDialogConv = params.show
    state.currentConv = params.show ? conv : null
    //console.log("currentConv", state.currentConv)
    state.liveMenuOpen = false
  },
  setPublicWallets( state, publicWallets){
    state.publicWallets = publicWallets
  },

  startClocker(state){
    //console.log("startClocker")
    //stop le précédent interval
    if(state.clock != null){ console.log("clear clock"); clearInterval(state.clock) }
    //relance un nouvel interval
    state.clock = setInterval(()=>{
      state.clockerD = moment(new Date(config.dateUnlock)).diff(moment(new Date()), 'days')
      state.clockerH = 24 - (new Date()).getHours() - 1
      state.clockerM = 60 - (new Date()).getMinutes() - 1
      state.clockerS = 60 - (new Date()).getSeconds() - 1
      //console.log("TICTAC", state.clockerD + "J • " + state.clockerH + "h · " + state.clockerM + "m · "+ state.clockerS +"sec")
    }, 1000)
  },

  stopClocker(state){
    if(state.clock != null){ console.log("clear clock"); clearInterval(state.clock) }
  },
};

const getters = {
  appVersionName: () => { return config.appVersionName },
  //nbNotifStatus: () => { return state.user.statusNbCommentsPending },
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
};
