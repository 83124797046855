<template>
<div class="d-md-none">
    <!-- TOP BAR -->
    <v-bottom-navigation fixed elevation="0" color="" background-color="#0D0D0D" id="bottom-bar">
      
      <v-btn text class="mx-1" to="/dashboard" title="Menu principal">
          <v-icon>mdi-view-dashboard</v-icon> 
      </v-btn>

      <template v-if="!$store.state.auth.isLogin">

        <v-btn text class="mx-1" to="/book" title="Journal de bord">
            <v-icon>mdi-book-open-page-variant-outline</v-icon> 
        </v-btn>
        <v-btn text class="mx-1" to="/code-des-pirates" title="Code des pirates">
            <v-icon>mdi-feather</v-icon> 
        </v-btn>

        <v-btn text to="/game/battle/menu" 
                elevation="0" class="mx-1"  title="Bataille navale">
          <v-icon>mdi-sail-boat</v-icon>
        </v-btn>

        <v-btn text to="/register" 
                elevation="0" class="mx-1"  title="Devenir un pirate">
          <v-icon>mdi-pirate</v-icon>
        </v-btn>

      </template>
        
      <v-btn v-if="$store.state.auth.isLogin" text class="mx-1" to="/wallet" title="Mes comptes">
          <v-icon>mdi-wallet</v-icon> 
      </v-btn>
      

      <v-btn v-if="$store.state.auth.isLogin" text class="mx-1" to="/market" title="Marché pirate">
          <v-icon>mdi-cart-arrow-right</v-icon> 
      </v-btn>

      <v-btn v-if="$store.state.auth.isLogin" text class="mx-1" to="/map" title="La carte">
          <v-icon>mdi-map-search</v-icon> 
      </v-btn>

      <v-btn v-if="$store.state.auth.isLogin" text class="mx-1" @click="openDialogMyStatus()" title="La carte">
        <v-badge color="red" class="" :value="nbNotifStatus" :content="nbNotifStatus">
          <v-icon :color="nbNotifStatus>0?'primary':''">mdi-thought-bubble</v-icon>
        </v-badge>
      </v-btn>

      <v-btn v-if="$store.state.auth.isLogin" text
            class="mx-1" to="/recruit" title="Parrainage">
          <v-icon small>mdi-qrcode</v-icon> 
      </v-btn>

    </v-bottom-navigation>
</div>
</template>
    
<script>
import core from '../../plugins/core.js'
export default {
  components: {
    
  },
  props: [],
  data: () => ({
    
  }),
  
  mounted: function(){
    
  },
  
  methods: {
    baseUrl(){ return core.baseUrl() },
    
    openDialogMyStatus(){
      this.$root.$emit("openDialogMyStatus")
    }
  },
  computed:{
    nbNotifStatus() { return this.$store.state.auth.user.statusNbCommentsPending },
    
  },
  
  watch: {
    
  }, 
}
</script>

<style>

</style>