<template>
  <div>
    <!-- MAIN MENU -->
    <v-navigation-drawer v-model="$store.state.app.drawerOpen" temporary
                        app disable-resize-watcher color="background">

      <v-list>
        <router-link to="/"  class="ml-5 mb-5 pb-2 d-inline-block primary--text font-main invisible-link" 
                            style="font-size:35px; margin-top:-23px; font-weight:800 !important;">
          {{ $store.state.app.siteName }}
        </router-link>
      </v-list>

      <div v-if="$store.state.auth.user.inBFC" 
          class="pa-5 pt-0">
        <router-link to="/inbox-crew">
          <v-img src="../../assets/img/black_fire_crew-600.png" width="210" 
                 style="border-radius:6px;"/>
        </router-link>
      </div>
      
      <v-divider/>

      <v-list v-if="$store.state.auth.isLogin">
        <v-list-item link to="/profil">
          <v-list-item-avatar>
            <v-gravatar height="22" style="border-radius:50%;"
                            :default-img="'retro'" :email="$store.state.auth.user.name"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ $store.state.auth.user.name }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="$store.state.auth.user.city != ''">{{ $store.state.auth.user.city }}</v-list-item-subtitle>
            <v-list-item-subtitle v-else>Position inconnue</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-account</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider/>

      <v-list>

        <template  v-if="$store.state.auth.isLogin && $store.state.auth.user.companies.length > 0">
          <template v-for="company in $store.state.auth.user.companies">
            <v-list-item :key="company._id" :to="'/company/'+company._id">
              
              <v-list-item-action class="mr-2">
                  <v-gravatar height="22" style="border-radius:50%;"
                          :default-img="'retro'" :email="company.name"/>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title><b>{{ company.name }}</b></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </template>
          <v-divider/>
        </template>

        <template v-if="showMenuComplete">
          <template v-for="item in itemsDrawer">
            <template v-if="item.name == 'divider'">
               <v-divider v-if="item.auth == false || $store.state.auth.isLogin" :key="item.to"/>
            </template>
            <template v-else>
              <v-list-item v-if="item.auth == false || $store.state.auth.isLogin" 
                          :key="item.to" link :to="item.to">

                <v-list-item-action class="mr-2">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title class="font-papyrus">{{ item.name }}</v-list-item-title>
                </v-list-item-content>
                
              </v-list-item>
            </template>
          </template>
          <v-list-item v-if="isTheApp" link to="/update-app-android">
            <v-list-item-action class="mr-2">
              <v-icon>mdi-download</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title class="font-papyrus">Mises à jour</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item>
          <v-list-item-content class="my-5">
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

  </div>
</template>

<script>
  //import router from '../../router/router'
  import Gravatar from 'vue-gravatar'
  const config = require('../../config/' + process.env.NODE_ENV)

  export default {
    name: 'main-menu',
    components: {
      'v-gravatar' : Gravatar,
    },
    props: [ 'showMenuComplete' ],
    data: () => ({
      drawer: null,
      itemsDrawer: [
        //{ name: 'Page d\'accueil',        to: '/',                      auth: false, icon: 'mdi-home' },
        { name: 'Menu principal',         to: '/dashboard',             auth: false, icon: 'mdi-view-dashboard' },
        { name: 'Journal de bord',        to: '/book',                  auth: false, icon: 'mdi-book-open-page-variant-outline' },
        { name: 'Code des pirates',       to: '/code-des-pirates',      auth: false, icon: 'mdi-feather' },
        { name: 'divider',                to: '/divider1',                                                  },
        { name: 'Bataille navale',        to: '/game/battle/menu',      auth: false, icon: 'mdi-sail-boat' },
        { name: 'Mon portefeuille',       to: '/wallet',                auth: true,  icon: 'mdi-wallet' },
        { name: 'Marché pirate',          to: '/market',                auth: true,  icon: 'mdi-cart-arrow-right' },
        { name: 'Carte principale',       to: '/map',                   auth: true,  icon: 'mdi-map-search' },
        { name: 'divider',                to: '/divider2',                                                        },
        { name: 'Mon profil',             to: '/profil',                auth: true,  icon: 'mdi-account-circle' },
        { name: 'Référencer mon activité',to: '/create-company',        auth: true,  icon: 'mdi-briefcase-plus-outline' },
        { name: 'Créer une annonce',      to: '/publish-offer',         auth: true,  icon: 'mdi-file-plus-outline' },
        { name: 'Mes annonces',           to: '/my-offers',             auth: true,  icon: 'mdi-offer' },
        { name: 'Propositions envoyées',  to: '/propositions/buyer',    auth: true,  icon: 'mdi-chevron-double-right'},
        { name: 'Propositions reçues',    to: '/propositions/seller',   auth: true,  icon: 'mdi-chevron-double-left'},
        { name: 'divider',                to: '/divider3',              auth: true,                                },
        { name: 'Devenir membre d\'équipage',to: '/pay',                auth: true,  icon: 'mdi-account-circle' },
        { name: 'Parrainage',             to: '/recruit',               auth: true,  icon: 'mdi-qrcode' },
        { name: 'Bouche à oreille',       to: '/share',                 auth: true,  icon: 'mdi-ear-hearing' },
        { name: 'divider',                to: '/divider4',              auth: true,                               },
        { name: 'Comptes publics',        to: '/public-wallets',        auth: true,  icon: 'mdi-wallet-bifold-outline' },
        { name: 'divider',                to: '/divider5',              auth: true,                               },
        //{ name: 'La monnaie',             to: '/mony',                  auth: false, icon: 'mdi-wallet' },
        //{ name: 'FAQ',                    to: '/faq',                   auth: false, icon: 'mdi-cloud-question' },
      ]
    }),

    
    mounted: function(){
      //initialise le theme sur "sombre" ou "lumineux" (dark : true / false)
      setTimeout(()=>{
        let themeDark =  localStorage.getItem("themeDark")
        if(themeDark != null) this.$vuetify.theme.dark = (themeDark=="true")

        localStorage.setItem("themeDark", this.$vuetify.theme.dark)
      }, 100)
      
    },
    
    methods: {
      logout(){
        this.$store.dispatch('auth/logout')
      },
      newThemeDark(){
        localStorage.setItem("themeDark", this.$vuetify.theme.dark)
      }
    },
    computed:{
      isTheApp(){ return config.appAndroidVersion != null },
    },
    filters: {
      capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
      }
    },
    watch: {
    }, 
    
    
  };
</script>
