<template>
  <v-snackbar
    v-model="$store.state.app.snackbar.show"
    timeout="7000" outlined
    :multi-line="multiLine"
    :color="$store.state.app.snackbar.color"
    dark class="snackbar-notif" left min-width="200"
  >
    <v-icon v-if="$store.state.app.snackbar.icon != null" :color="$store.state.app.snackbar.color" small class="mr-2">
      mdi-{{ $store.state.app.snackbar.icon }}
    </v-icon>

    <span style="font-size:13px;" v-html="$store.state.app.snackbar.title"></span><br>
    <small v-html="nl2br($store.state.app.snackbar.text)"></small>
    
    <template v-slot:action="{ attrs }">
      <v-btn x-small fab outlined v-bind="attrs" 
            v-if="$store.state.app.snackbar.path == null && $store.state.app.snackbar.dispatchAction != null"
            @click="onClickDispatch()">
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
      <v-btn x-small fab outlined v-bind="attrs" 
            v-else-if="$store.state.app.snackbar.path == null && $store.state.app.snackbar.dispatchAction == null"
            @click="$store.dispatch('app/openSnackbar', { show: false })">
        <v-icon>mdi-check</v-icon>
      </v-btn>
      <v-btn x-small fab outlined v-bind="attrs" 
            v-else @click="goto" :color="$store.state.app.snackbar.color">
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
  export default {
    data: () => ({
      multiLine: true,
      snackbar: true,
      text: `I'm a multi-line snackbar.`,
    }),
    methods: {
      onClickDispatch(){
        this.$store.dispatch(this.$store.state.app.snackbar.dispatchAction, this.$store.state.app.snackbar.dispatchParams)
        this.$store.dispatch('app/openSnackbar', { show: false })
      },
      goto(){
        console.log("goto", this.$store.state.app.snackbar)
        if(this.$store.state.app.snackbar.path != null) {
          this.$router.push(this.$store.state.app.snackbar.path)
          this.$store.dispatch('app/openSnackbar', { show: false })
        }
      },
      nl2br: function(str, is_xhtml){
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'
        let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
        return newStr
      },
    },
  }
</script>