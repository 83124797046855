<template>
  <v-list two-line dense class="drawerRight" >
    
    <v-subheader class="bold subheader" style="">
      <v-btn fab x-small @click="$store.dispatch('app/setLiveMenuOpen', !$store.state.app.liveMenuOpen)" 
             class="ml-1 mr-3 mt-2">
              <v-badge color="red" v-if="!$store.state.app.liveMenuOpen"
                    :class="$store.getters['auth/privateMsgNotRead'] > 0 ? '' : ''" 
                    :content="$store.getters['auth/privateMsgNotRead']" 
                    :value="$store.getters['auth/privateMsgNotRead']">
              <v-icon :color="$store.getters['auth/privateMsgNotRead'] > 0 ? 'red' : 'blue'">mdi-message</v-icon>
              </v-badge>
              <v-icon v-if=" $store.state.app.liveMenuOpen">mdi-close</v-icon>
      </v-btn>
      <v-list-item-content class="mt-1">
        <b>Discussions privées</b>
      </v-list-item-content>
    </v-subheader>

    <v-divider style="margin-top:15px;"/>

    <template v-if="$store.state.auth.conversations.length > 0">
      <template v-for="(conv, p) in $store.state.auth.conversations" >
        <v-list-item class="px-2 py-0" :key="p" style="height:30px;min-height:50px;"
                    @click="$store.dispatch('app/setShowDialogConv', { show: true, conv: conv })"
                    >
          <template v-if="conv.company == null">
            <template v-if="conv.user1._id != $store.state.auth.user._id">
              <v-list-item-icon class="mr-2">
                <v-badge color="red" class="badge-nbMsg" small 
                        :content="nbMsgNotRead(conv._id)" 
                        :value="nbMsgNotRead(conv._id)">
                  <v-gravatar :height="25" :width="25" class="ml-2" 
                              style="border-radius:50%;"
                              :default-img="'retro'" :email="conv.user1.name"/>
                </v-badge>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="">
                  <b class="">{{ conv.user1.name }}</b> · <small>{{ conv.user1.city }}</small>
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <template v-else>
              <v-list-item-icon class="mr-2">
                <v-badge color="red" class="badge-nbMsg" small 
                        :content="nbMsgNotRead(conv._id)" 
                        :value="nbMsgNotRead(conv._id)">
                  <v-gravatar :height="25" :width="25" class="ml-2" 
                              style="border-radius:50%;"
                              :default-img="'retro'" :email="userDest(conv).name"/>
                </v-badge>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ userDest(conv).name }} · <small>{{ userDest(conv).city }}</small>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </template>

          <template v-else>
            <template v-if="conv.user1._id != $store.state.auth.user._id">
              <v-list-item-icon class="mr-2">
                <v-badge color="red" class="badge-nbMsg" small 
                        :content="nbMsgNotRead(conv._id)" 
                        :value="nbMsgNotRead(conv._id)">
                  <v-gravatar :height="25" :width="25" class="ml-2" 
                              style="border-radius:50%;"
                              :default-img="'retro'" :email="userDest(conv).name"/>
                </v-badge>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ userDest(conv).name }} · <small>{{ userDest(conv).city }}</small>
                </v-list-item-title>
                  <v-list-item-title>
                    <v-gravatar :height="15" :width="15" class="mr-1" 
                              style="border-radius:50%;margin-bottom:-3px;"
                              :default-img="'retro'" :email="conv.company.name"/>
                    {{ conv.company.name }}
                  </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-icon class="mr-2">
                <v-badge color="red" class="badge-nbMsg" small 
                        :content="nbMsgNotRead(conv._id)" 
                        :value="nbMsgNotRead(conv._id)">
                  <v-gravatar :height="25" :width="25" class="ml-2" 
                              style="border-radius:50%;"
                              :default-img="'retro'" :email="conv.company.name"/>
                </v-badge>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ conv.company.name }} · <small>{{ conv.company.city }}</small>
                </v-list-item-title>
                  <v-list-item-title>
                    <v-gravatar :height="15" :width="15" class="mr-1" 
                              style="border-radius:50%;margin-bottom:-3px;"
                              :default-img="'retro'" :email="userDest(conv).name"/>
                    {{ userDest(conv).name }}
                  </v-list-item-title>
              </v-list-item-content>
            </template>
          </template>

        </v-list-item>
      </template>
    </template>

    <template v-else>
      <v-list-item class="px-2">
        <v-list-item-icon class="mx-2"><v-icon>mdi-cancel</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title><b>Vous n'avez aucun contact</b></v-list-item-title>
          <p>
            Parcourez la carte pour trouver des joueurs à qui parler !<br>
          <v-btn to="/map" small class="mt-3" width="170" color="blue"><v-icon>mdi-map-search</v-icon> La carte</v-btn>
          </p>
          <v-list-item-title></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <span style="opacity:0">{{ $store.state.auth.lastMsgReceived }}</span>
  </v-list>
</template>

<style>
  .badge-nbMsg .v-badge__badge{
    inset: auto auto calc(82% - 4px) calc(82% - 4px) !important;
    font-weight: bold;
  }
  .subheader{
    margin-bottom:7px;
  }

  @media (max-width: 960px) {
    .subheader{
      margin-bottom:-1px;
    }
  }
</style>
<script>

//import axios from 'axios'
//import router from '../router/router'
import core from '../../plugins/core.js'
import Gravatar from 'vue-gravatar'

export default {
  name: 'drawer-right',
  props: [],
  components: { 'v-gravatar' : Gravatar },
  data:() => ({
   timer: null
  }),
  beforeDestroy () {
    if(this.timer != null){
      clearInterval(this.timer)
      this.timer = null
    } 
  },
  async mounted(){
    // if(this.timer != null){
    //   clearInterval(this.timer)
    //   this.timer = null
    // } 
    // this.timer = setInterval(()=>{
    //   this.$store.dispatch('live/whoIsOnline')
    // }, 120000)
  },

  methods: {

    nbMsgNotRead: function(convId){
      let nbMsgNotRead = 0
      this.$store.state.auth.conversations.forEach(conv => {
        if(convId == conv._id){
          let myLastDate = null
          conv.dateLastRead.forEach((date) => {
            if(date.userId == this.$store.state.auth.user._id) myLastDate = date.date
          })
          
          conv.messages.forEach(msg => {
            if(msg.created > myLastDate) console.log("nbMsgNotReads", msg.created, myLastDate)
            if(msg.created > myLastDate) nbMsgNotRead++
          });
        }
      })
      return nbMsgNotRead
    },
    userDest(conversation){ 
      //console.log("conversation", conversation.user1._id, conversation.user2)
      if(conversation == null) return {} 
      return (conversation.user1._id == this.$store.state.auth.user._id) ? conversation.user2 : conversation.user1 
    },
    baseUrl(){ return core.baseUrl() },

  },
  computed: {
  }
}
</script>