const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/dashboard.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    meta: { 
      requiresAuth: false,
      soonAllowed: true
    }
  },
  {
    path: '/register/:recruitCode?',
    name: 'register',
    component: () => import('../views/register.vue')
  },
  {
    path: '/cgv',
    name: 'cgv',
    component: () => import('../views/cgv.vue')
  },
  {
    path: '/cgu',
    name: 'cgu',
    component: () => import('../views/cgu.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact.vue')
  },

  /**************************** GAME ****************************/

  {
    path: '/game/battle/menu',
    name: 'game-battle-menu',
    component: () => import('../views/game/battle/menu.vue')
  },
  {
    path: '/game/battle/create',
    name: 'game-battle-create',
    component: () => import('../views/game/battle/create.vue')
  },
  {
    path: '/game/battle/join',
    name: 'game-battle-join',
    component: () => import('../views/game/battle/join.vue')
  },
  {
    path: '/game/battle/load',
    name: 'game-battle-load',
    component: () => import('../views/game/battle/load.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/game/battle/ranking',
    name: 'game-battle-ranking',
    component: () => import('../views/game/battle/ranking.vue')
  },
  {
    path: '/game/battle/play/:gameId',
    name: 'game-battle-play',
    component: () => import('../views/game/battle/play.vue')
  },

  /**************************** CUSTOM ****************************/

  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/dashboard.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/share',
    name: 'share',
    component: () => import('../views/share.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/public-wallets',
    name: 'public-wallets',
    component: () => import('../views/public-wallets.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/wallet/:walletUid?',
    name: 'wallet',
    component: () => import('../views/custom/wallet.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('../views/account/change-password.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reset-password',
    name: 'reset-password-init',
    component: () => import('../views/account/reset-password-init.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/reset-password/:userId/:pwdToken',
    name: 'reset-password-form',
    component: () => import('../views/account/reset-password-form.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/change-email',
    name: 'change-email',
    component: () => import('../views/account/change-email.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/delete-account',
    name: 'delete-account',
    component: () => import('../views/account/delete-account.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/cancel-subscription',
    name: 'cancel-subscription',
    component: () => import('../views/account/cancel-subscription.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/step/geoloc',
    name: 'step-geoloc',
    component: () => import('../views/custom/step-geoloc.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/profil',
    name: 'profil',
    component: () => import('../views/custom/profil.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/config',
    name: 'config',
    component: () => import('../views/custom/config.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/market',
    name: 'market',
    component: () => import('../views/custom/market.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('../views/mainmap.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/book',
    name: 'book',
    component: () => import('../views/custom/book.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/code-des-pirates',
    name: 'code-des-pirates',
    component: () => import('../views/custom/code-pirate.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/post/:postId',
    name: 'post',
    component: () => import('../views/custom/post.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/custom/pay.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/recruit',
    name: 'recruit',
    component: () => import('../views/recruit.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/airpnp',
    name: 'airpnp',
    component: () => import('../views/custom/airpnp.vue'),
    meta: { requiresAuth: true }
  },
  /**       OFFER  *****/
  {
    path: '/publish-offer',
    name: 'publish-offer',
    component: () => import('../views/custom/form-offer.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-offer/:offerId',
    name: 'edit-offer',
    component: () => import('../views/custom/form-offer.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/report/:entityType/:entityId',
    name: 'report-entity',
    component: () => import('../views/confirm/report-entity.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/offer/:offerId',
    name: 'offer',
    component: () => import('../views/custom/offer.vue'),
    meta: { requiresAuth: false }
  },
  /**       COMPANY  *****/
  {
    path: '/create-company',
    name: 'create-company',
    component: () => import('../views/company/form-company.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-company/:companyId',
    name: 'edit-company',
    component: () => import('../views/company/form-company.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/delete-company/:companyId',
    name: 'delete-company',
    component: () => import('../views/company/delete-company.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/company/:companyId',
    name: 'company-page',
    component: () => import('../views/company/company.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/user/:userId',
    name: 'user-page',
    component: () => import('../views/account/user.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/propositions/buyer',
    name: 'propositions-buyer',
    component: () => import('../views/custom/proposition-buyer.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/propositions/seller',
    name: 'propositions-seller',
    component: () => import('../views/custom/proposition-seller.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/my-offers',
    name: 'my-offers',
    component: () => import('../views/custom/my-offers.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/private/:userId/:companyId?',
    name: 'private',
    component: () => import('../views/custom/private.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/payment/thanks',
    name: 'payment-thanks',
    component: () => import('../views/confirm/payment.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/payment/:offerId',
    name: 'payment-offer',
    component: () => import('../views/custom/payment-offer.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/info/locked',
    name: 'info-locked',
    component: () => import('../views/custom/info-locked.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/info/plan-fst-offer',
    name: 'info-plan-fst-offer',
    component: () => import('../views/custom/info-plan-fst-offer.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/confirm-email/:userId/:emailToken',
    name: 'confirm-email',
    component: () => import('../views/confirm/confirm-email.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/confirm-register',
    name: 'confirm-register',
    component: () => import('../views/confirm/confirm-register.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/soon',
    name: 'soon',
    component: () => import('../views/soon.vue'),
    meta: { 
      requiresAuth: false,
      soonAllowed: true
    }
  },
  {
    path: '/unlocked',
    name: 'unlocked',
    component: () => import('../views/unlocked.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/init-account/:stepInit?',
    name: 'first-step',
    component: () => import('../views/custom/first-step.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/inbox-crew',
    name: 'inbox-crew',
    component: () => import('../views/custom/inbox-crew.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/webview',
    name: 'webview',
    component: () => import('../views/custom/webview.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/install-app-android',
    name: 'install-app-android',
    component: () => import('../views/custom/install-app-android.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/update-app-android',
    name: 'update-app-android',
    component: () => import('../views/custom/update-app-android.vue'),
    meta: { requiresAuth: false }
  },
  
]

exports.routes = routes
