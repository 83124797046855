<template>
  <v-card elevation="2" style="border-radius:8px;border:0px solid rgb(30, 30, 30)" 
          class="mb-3 pa-2 p0" color="rgba(0,0,0,0.9)"
          v-if="entity.currentMsg != null && entity.currentMsg != ''">
    <v-toolbar color="transparent" elevation="0" style="cursor:pointer;" height="80"
              @click="showDialogEntity(entity)" class="toolbar-msg-status" 
              title="cliquer pour afficher l'auteur sur la carte">
      <v-gravatar height="32" style="border-radius:50%;" class="mr-2"
                  :default-img="'retro'" :email="entity.name"/>
        <div>

          <v-icon small class="mr-1" :color="entity.category.iconColor" v-if="entity.dataType=='company'">mdi-{{ entity.category.icon }}</v-icon>
          <v-icon small class="mr-1" v-else-if="entity.dataType=='user'">mdi-account</v-icon>
          <b class=""><small>{{ entity.name }}</small></b><br>
          <small v-if="entity.city!=null && entity.city!=''" class="mr-1">
            <v-icon small class="mb-1">mdi-map-marker</v-icon> {{ entity.city }}
          </small>

          <small>
            <v-icon x-small :color="colorActivity" class="mb-1">mdi-circle</v-icon>
            Actif <time-ago :datetime="entity.updated" :refresh="60" long 
                            :locale="$vuetify.lang.current"/>
          </small>


        </div>
    </v-toolbar>
    <v-divider></v-divider>

    <div v-if="entity.dataType=='company'" class="mt-2">
      <v-icon  class="ml-4 mr-1 mb-1" :color="entity.iconColor" v-if="entity.dataType=='company'">mdi-{{ entity.iconFa }}</v-icon>
      <b>{{  entity.ownModel.name }}</b>
    
    </div>
    <v-card-text class="pt-2 pb-4" :class="entity.dataType=='company' ? 'font-papyrus' : ''" 
                  style="font-size:15px;" v-html="nl2br(entity.currentMsg)">
    </v-card-text>
    <v-divider></v-divider>
    <v-toolbar dense color="transparent" elevation="0">
      <v-btn small  rounded class="mr-3 blue--text" @click="openDialogComments()" v-if="entity.dataType=='user'">
        <v-icon small color="blue" class="mr-1">mdi-comment</v-icon> {{ entity.statusNbComments }}
      </v-btn>
      <v-icon small class="mr-1" title="nombre de vues du status" v-if="entity.dataType=='user'">mdi-eye</v-icon> 
      <b>{{ entity.statusViews }}</b>
      <v-spacer></v-spacer>
      <v-btn small fab color="transparent" class="mr-1" @click="showDialogEntity(entity)">
        <v-icon small>mdi-map-marker</v-icon>
      </v-btn>
      <v-btn v-if="entity._id != $store.state.auth.user._id" 
            small fab  color="transparent"  
            @click="startConversation(entity._id)">
        <v-icon color="blue" small class="">mdi-message</v-icon>
      </v-btn>
    </v-toolbar>

  </v-card>
  </template>
  
  <style scoped>
    .toolbar-msg-status:hover{
      background-color:rgb(14, 17, 17) !important;
    }
  </style>
  
  <script>
  
  //import axios from 'axios'
  //import router from '../router/router'
  import core from '../../plugins/core.js'
  
  import Gravatar from 'vue-gravatar'
  import {TimeAgo} from "vue2-timeago"
  
  export default {
    name: 'empty',
    props: ['entity'],
    components: { 'v-gravatar' : Gravatar, TimeAgo },
    data: () => ({
  
    }),
    async mounted(){
      
    },
    methods: {
      
      openDialogComments(){
        console.log("openDialogComments", this.entity._id)
        this.$root.$emit("openDialogComments", { refId: this.entity._id, refType : 'status' })
      },
      startConversation(userId){
        this.$root.$emit("closeListMsgPerso")
        this.$store.dispatch('app/openConv', { userId: userId }) 
      },
      showDialogEntity(entity) { 
        this.$root.$emit("showEntityOnMap", entity)
        this.$root.$emit('closeDialogResMap')
        //this.$root.$emit("openDialogEntity", this.entity)
      },
      nl2br: function(str){
        return core.completeText(str, null)
      },
  
    },
    computed: {
      colorActivity(){
        let now = new Date()
        let date2min = new Date()
        date2min.setMinutes(now.getMinutes() - 2)
        let date1h = new Date()
        date1h.setHours(now.getHours() - 1)
        
        if(new Date(this.entity.updated) > date2min) return 'green'
        if(new Date(this.entity.updated) > date1h) return 'orange'
        return 'red'
      }
      
    }
  }
  </script>
  