import Vue from 'vue'
import VueRouter from 'vue-lang-router'
//import store from '../store'

import NProgress from 'nprogress'
import store from "../store"

import translations from '../lang/translations'
import localizedURLs from '../lang/localized-urls'

const config = require('../config/' + process.env.NODE_ENV)

NProgress.configure({ showSpinner: false });

Vue.use(VueRouter, {
  defaultLanguage: 'fr',
  translations,
  localizedURLs,
});
 
const {routes} = require('./routes-'+store.getters['app/appVersionName'])

//console.log("routes", routes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
  setTimeout(()=>{
    window.scrollTo(0, 0)
  }, 200)
  
})

//avant chaque changement de route (url dans le navitateur)
router.beforeEach(async (to, from, next) => {

  if(config.locked == true){
    await store.dispatch('auth/checkAuth', null, { root: true })
    //console.log("router.beforeEach", store.state.auth.isLogin, store.state.auth.user.isAdmin, store.getters['auth/isVIP'], localStorage.getItem("vipcode"))
    let isVIP = config.vipcodes.indexOf(localStorage.getItem("vipcode")) > -1
    if((!store.state.auth.user.isAdmin) && !isVIP){
      //console.log("router.beforeEach user not ADMIN", to)
      if(to.meta.soonAllowed != true) return next('/soon')
      else return next()
    }
  }
  //console.log("router.beforeEach OK")
  if(to.meta != null //si cette route nécessite une authentification 
  && to.meta.requiresAuth == true){
    //vérifie que le user est connecté
    await store.dispatch('auth/checkAuth', null, { root: true })

    if(store.state.auth.isLogin) {//si le user est connecté
      if(to.meta != null //si cette route nécessite d'être admin
        && to.meta.requiresAdmin == true){
        if(store.getters['auth/isAdmin']) return next()//soit il est admin, et il peut y aller
        else return next('/login') //soit il est pas admin, il doit se logguer
      }else{ 
        if(store.state.auth.user.inBFC == null && to.path != "/init-account") return next('/init-account')
        else         
        return next()
      }
    }//si il n'est pas connecté, il doit se logguer
    else return next('/login')
  }else{//si la route n'a pas besoin d'authentification, il peut y aller
    if(store.state.auth.isLogin 
    && store.state.auth.user.inBFC == null 
    && to.path != "/init-account") 
         return next('/init-account')
    else return next()
  }
})


export default router
